.custom-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  font-size: 2rem;
  filter: drop-shadow(0 0 4px #6c757d);
  cursor: pointer;
  color: #fff;
}

.previous-items-container {
  position: absolute;
  width: 41px;
  left: 0;
  cursor: pointer;
}

.next-items-container {
  position: absolute;
  width: 41px;
  right: 0;
  cursor: pointer;
}

.full-height-scroll-indicator {
  height: 100%;
  top: 0;
}

.mid-height-scroll-indicator {
  height: 90%;
  top: 10%;
}

.short-height-scroll-indicator {
  height: 75%;
  top: 25%;
}

.homepage-section-title {
  font-weight: 500;
  line-height: 1;
}

.next-items-container:hover,
.previous-items-container:hover {
  background: rgba(255, 255, 255, 0.9);
}

.homepage-component:hover .next-items-container,
.homepage-component:hover .previous-items-container {
  background: rgba(255, 255, 255, 0.9);
}

.previous-items {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  z-index: 10;
  font-size: 2rem;
  color: #6c757d;
  display: none;
}

.next-items {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 10;
  font-size: 2rem;
  color: #6c757d;
  display: none;
}

.homepage-component:hover .previous-items,
.homepage-component:hover .next-items {
  display: block;
}

.home-row {
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.home-row::-webkit-scrollbar {
  display: none;
}

.homepage-component-item,
.homepage-component-link {
  width: 47%;
}

.homepage-component-huge-item {
  width: 90%;
}

.element {
  position: relative;
  cursor: pointer;
}

.element-info {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  font-size: 2rem;
  filter: drop-shadow(0 0 4px #6c757d);
  cursor: pointer;
  color: #fff;
}

.element:hover .element-info {
  opacity: 1;
  transition: opacity 0.3s linear;
}

#element-details-modal .modal-dialog .ratings {
  background: #fff;
}

.text-tile {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.text-tile-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media (min-width: 576px) {
  .homepage-component-huge-item {
    width: 75%;
  }
  .homepage-component-item,
  .homepage-component-link {
    width: 31%;
  }
  .element-info {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .homepage-component-huge-item {
    width: 65%;
  }
  .homepage-component-item,
  .homepage-component-link {
    width: 24%;
  }
}

@media (min-width: 992px) {
  .homepage-component-huge-item {
    width: 50%;
  }
  .homepage-component-item,
  .homepage-component-link {
    width: 19%;
  }
}

@media (min-width: 1200px) {
  .homepage-component-huge-item {
    width: 45%;
  }
  .homepage-component-item,
  .homepage-component-link {
    width: 16%;
  }
}
