.search-wrap .form-control, .search-wrap .btn, .add-custom-item .form-control {padding: .5em 1em; font-size: 2rem;}
.search-wrap .form-control, .add-custom-item .form-control  {font-style: italic;}
.search-wrap {max-height: 52px; margin-bottom: 1em;}
.search-wrap input, .add-custom-item input {border: 0 none; border-right: 1px solid #ccc;}
.search-wrap .form-control, .search-wrap .btn, .add-custom-item .form-control {padding: .4em .8em; font-size: 1.2rem;}
.search-wrap input {font-family: 'playfair display', Georgia, 'Times New Roman', Times, serif;}

.tool-image {
    height: 25px;
    padding-right: 5px;
}