
.badge-meal-type {color: #666; background: #fff;
    top: .8em;
    left: .6em;
    position: absolute;
    width: auto;
    border-radius: 0;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    font-weight: 300;
    height: 45px;}
 .delete-meal {height: 50px; padding: 2px 12px;}
 .public-plan-recipe-link a {
    color:black;
 }
 .public-plan-recipe-link a:hover {
    color:rgb(255, 92, 38);
 }