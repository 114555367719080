.journal-title, .journal-section, .journal-entry {
  font-family: 'Handlee', cursive;
}

#journal-body {
  position: relative;
}

#current-week-bookmark-top, #current-week-bookmark-bottom {
  font-size: 30px;
  position: absolute;
  right: 20px;
}

#current-week-bookmark-top {
  top: -2px;
}

#current-week-bookmark-bottom {
  top: 20px;
}

td.journal-entry-time {
  width: 1px;
  white-space: nowrap;
} 

#cooking-journal a {
  color: #000;
  &:hover {
    text-shadow: 0 1px 1px #888;
  }
}

#cooking-journal .delete-recipe:hover {
  text-shadow: 0 1px 1px #888;
}

ul.collage {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
}

ul.collage li, .profile-photo {  
  display: inline-block;
  background: white;
  border: 1px solid #fff;
  -moz-box-shadow: 0 2px 5px #888;
  box-shadow: 0 2px 5px #888;
  position: relative;
} 

ul.collage li {  
  padding: 5px 5px 20px 5px;
} 

.profile-photo {  
  padding: 10px 10px 40px 10px;
} 

ul.collage li img {
  object-fit: cover;
  aspect-ratio: 1/1;
  width: 100px;
}

.profile-photo {
  transform: rotate(-5deg);

  img {
    object-fit: cover;
    aspect-ratio: 1/1;
    width: 250px;
  }
}

ul.collage li.pic-0 { z-index: 1; transform: rotate(-10deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-10deg); } }
ul.collage li.pic-1 { z-index: 5; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-2 { z-index: 3; transform: rotate(8deg); &:hover { z-index: 100; transform: scale(1.2) rotate(8deg); } }
ul.collage li.pic-3 { z-index: 2; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-4 { z-index: 4; transform: rotate(4deg); &:hover { z-index: 100; transform: scale(1.2) rotate(4deg); } }
ul.collage li.pic-5 { z-index: 6; transform: rotate(-10deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-10deg); } }
ul.collage li.pic-6 { z-index: 1; transform: rotate(5deg); &:hover { z-index: 100; transform: scale(1.2) rotate(5deg); } }
ul.collage li.pic-7 { z-index: 4; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-8 { z-index: 2; transform: rotate(4deg); &:hover { z-index: 100; transform: scale(1.2) rotate(4deg); } }
ul.collage li.pic-9 { z-index: 3; transform: rotate(12deg); &:hover { z-index: 100; transform: scale(1.2) rotate(12deg); } }
ul.collage li.pic-10 { z-index: 5; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-11 { z-index: 6; transform: rotate(4deg); &:hover { z-index: 100; transform: scale(1.2) rotate(4deg); } }
ul.collage li.pic-12 { z-index: 1; transform: rotate(-10deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-10deg); } }
ul.collage li.pic-13 { z-index: 5; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-14 { z-index: 3; transform: rotate(4deg); &:hover { z-index: 100; transform: scale(1.2) rotate(4deg); } }
ul.collage li.pic-15 { z-index: 2; transform: rotate(8deg); &:hover { z-index: 100; transform: scale(1.2) rotate(8deg); } }
ul.collage li.pic-16 { z-index: 4; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-17 { z-index: 6; transform: rotate(-10deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-10deg); } }
ul.collage li.pic-18 { z-index: 1; transform: rotate(5deg); &:hover { z-index: 100; transform: scale(1.2) rotate(5deg); } }
ul.collage li.pic-19 { z-index: 5; transform: rotate(-3deg); &:hover { z-index: 100; transform: scale(1.2) rotate(-3deg); } }
ul.collage li.pic-20 { z-index: 3; transform: rotate(4deg); &:hover { z-index: 100; transform: scale(1.2) rotate(4deg); } }

.pb-1 {
  padding-bottom: 0.45rem !important;
}

.ps-1 {
  padding-left: 0.75rem !important;
}

.pe-1 {
  padding-right: 0.65rem !important;
}

.lh-sm {
  line-height: 1.19 !important;
}

@media (min-width: 1200px) {
  .fs-5 {
    font-size: 1.25rem !important;
  }

  .fs-2 {
    font-size: 1.9rem !important;
  }
}