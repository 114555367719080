#playlist .list-group-item {
    cursor: pointer;
}

#playlist .list-group-item.active {
    
    cursor: default;
}

#playlist-download a {text-decoration: none;}

.list-heading {background-color: #fff; color: rgb(0, 0, 0);}

.video-list h3 {color: #000; margin-bottom: 4px;}

.list-group-item {
    
    border: 1px solid #efefef;
}

.list-group-item.active {color: #000; border: 1px solid rgba(0, 0, 0, 0.125);}


