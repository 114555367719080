.rated-by-count {
  font-size: 0.9em;
  margin: 1rem 0 2rem 0;
}
.reviews-timing-rating h3 {
  font-size: 1.2rem;
  margin: 8px 0;
}
.single-rating {
  font-size: 1em;
}
.document-type {
  font-size: 1rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-weight: lighter;
}
