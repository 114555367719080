ul.meal-plan-batching-icons li img {
  width: 30px;
  height: 29.43px;
}
ul.meal-plan-batching-meal li {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.jumbotron {background-color: #fff;}
.jumbotron h1 {clear: both;}
.jumbotron .ratings {float: left;}
.jumbotron .ddp-time {font-size: 1.25rem !important;}
.ratings-icons span {font-size: 18px;}
.ratings .fa-star, .time-rating {color: #f47621 !important;}
.single-rating {font-size: 1.4em; float: left;}
.write-review button {color: #fff; text-decoration: none;}
.slash {margin-top: 4px; float: left;}
.ddp-info .ratings {float: left;}
.ddp-time {margin-left: 5px; float: left; font-size: 1.2em;}
.mp-reviews {background: #fff;}
.mp-description {font-size: 1.6rem;}
.ddp-info h1 {padding: .2em 0 .1em 0;}

.rating-categories h3 {
  margin-bottom: 0.09em;
}
.rating-categories p {
  font-size: 15px;
}

.nav-tabs .nav-link.active {
  background-color: #f6f6f6;
  color: #17b3d0;
}
.nav-link.active {
  background: none;
}

.ddp-actions, .ddp-actions .btn {font-size: 1.5rem;}
.ddp-actions .btn {margin-right: 2px;}
.ddp-add-actions {font-size: 2rem; color: #f47621;}
.add-action {background-color: #f6f6f6; padding: 1rem;}
.spin-action {background: none;}
@media (max-width: 768px) { .mp-deets {padding: 1.4rem 1.1rem 2.1rem 2rem !important;}
.ddp-info h1 {font-size: 1.8em; padding: .35em 0;}


}

.min-width-460-md {
  min-width: 530px !important;
}

/*  Search Filter Flyout Global/Desktop */
#search-filter-col {
  z-index: 4;
}
#search-filter-col h2 {
  margin: 0.8em 0;
}
.search-filters-wrap {
  margin: 1.5em 0 0 0.9em;
}
#search-filter-col label {
  font-size: 1.2em;
  line-height: 1.7em;
}
.search-filter-mobile-footer {
  display: none;
}

@media (min-width: 768px) {
  #search-filter-col {
    transition: all 0.2s ease-in-out;
    position: absolute;
    height: 100% !important;
    left: 0;
    top: 0;
    padding: 7em 2em 3em 20px;
    min-width: 300px;
    max-width: 400px;
  }
}

/*  Search Filter Mobile */
@media (max-width: 767px) {
  #search-filter-col {
    transition: all 0.2s ease-in-out;
    position: absolute;
    height: 100% !important;
    width: 100%;
    left: 0;
    top: 0;
    padding: 7em 2em 3em 20px;
  }
  #search-filter-col h2 {
    font-size: 1.7em;
    border-top: 1px solid #fff;
    margin: 1.4em 0 0 0;
    padding: 1.5em 0 0 0;
  }
  #search-filter-col label {
    font-size: 1.4em;
    line-height: 1.8em;
  }
  .search-filter-mobile-footer {
    display: block;
    position: fixed;
    top: 900px;
    z-index: 8;
  }
}

.ddp-poster-wrap {
  text-align: center;
  max-width: 600px;
}

.btn-stacked {text-align: center;}
.fa-action {font-size: 1.2em; color: #f47621;}
.fa-action-text {font-size: 14px !important; color: #000; }
.btn-stacked span {font-size: .8em;
    font-family: 'poppins', sans-serif; font-weight: 300;}

/* Ratings Modal */
.modal-dialog .ratings {
  background: #f7f2ea;
}

.back-bar:before {
  content: "< Back to Plan";
}
.back-bar {
  width: 100%;
  font-size: 22px;
  background: #a3cf49;
  color: #fff;
  margin: 0 auto;
  padding: 12px 10px 20px 10px;
  position: fixed;
  z-index: 3;
  opacity: 1;
  top: 77px;
  left: 1px;
}

.back-bar .recipe-wrap {
  top: 10em !important;
}

.orange .fa-action,
.orange .fa-action-text {
  color: #fff !important;
  padding-top: 10px;
}

.plan-type {
  font-size: 0.8rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-left: 0.9rem;
}

.plan-type-mobile {
  font-size: 0.8rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-left: 0.2em;
  padding-bottom: 0.6em;
}

@page: footer {
  border-bottom: 5px solid #000;
}
@page: header {
  border: 5px solid #666;
}

@media (max-width: 576px) {
  .back-bar {
    top: 71px !important;
  }
}

.ddp-add-actions .btn:first-child {
  padding-left: 0;
}

@media print {
  @page {
    margin-top: 35px;
    margin-bottom: 50px;
    background-size: cover;
  }
  .xs-intro {
    float: left;
    margin-top: 10px;
  }
  body {
    padding: 8px !important;
  }
  .watermark {
    display: block;
    text-align: left;
    max-width: 120px;
    margin: 20px 0 0 0 !important;
    float: right;
  }
  .recipe-watermark {
    display: block;
    text-align: right;
    max-width: 120px;
    margin: 20px 0 0 0 !important;
    float: left;
  }
  .strip-margin-print {
    margin: 0 !important;
    padding: 0 !important;
  }
  .container-fluid {
    margin: 5px;
    padding: 5px;
  }
  .accordion-header {
    border-bottom: 1px solid #ccc;
    margin: 10px 0 !important;
  }
  .float-start {
    display: none;
  }
  .margin-print {
    margin-top: 10px;
  }
  .plan-tabs {
    display: none;
  }
  .first-accordion-print .accordion-button,
  .preps .accordion-button {
    page-break-before: avoid;
  }
  .first-accordion-print .watermark {
    display: none;
  }
  .collapsed .h-playlist {
    color: #666 !important;
  }
  button {
    display: none;
  }
  .main-meal-plan-wrap .accordion-button:not(.collapsed) {
    border: 0 none;
  }
  .recipe-single-img {
    padding-right: 20px;
  }
  .recipe-sidebar {
    text-align: left !important;
  }
  .accordion-body,
  .show .accordion-body,
  #preps .accordion-body {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 none !important;
  }
  .accordion-button {
    padding: 0;
  }
  .meal-plan-poster {
    display: none;
  }
  .pl-print {
    margin-left: 10px;
  }
  .pt-print {
    margin-top: 5px !important;
  }
  body {
    margin: 8px 0;
  }
  .my-2 {
    margin: 0;
  }
  .mt-md-3 {
    margin-top: 10px !important;
  }
  .col-print .text-center,
  .col-print {
    text-align: left !important;
    padding: 0;
  }
  .mb-5 {
    margin-bottom: 10px !important;
  }
  .p-md-4 {
    padding: 0 !important;
  }
  .btn-stacked,
  .main-menu,
  .nav-align-right,
  .form-control,
  .dropdown,
  .fa-image,
  .fa-info-circle,
  .footer .logo,
  .single-recipe-actions,
  .add-to-plan,
  .btn,
  .footer-icons,
  nav,
  .nav-tabs,
  .ratings-icon,
  .footer-fine-print,
  .ddp-info h1 {
    display: none;
  }
  .recipe-featured-image {
    float: left;
    padding-right: 10px;
  }
  .recipe-checkbox-table td {
    padding: 4px 0 4px 0 !important;
  }
  main {
    margin-top: 0 !important;
  }
  .ratings .fa-star {
    display: none;
  }
  .markdown-notes em,
  .markdown-notes strong {
    font-weight: 300 !important;
  }
  .my-auto,
  .my-1 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .recipe-ingredients-directions,
  .recipe-checkbox-table {
    font-size: 13px !important;
  }
  .allergens li {
    text-align: left !important;
  }
  .print-full {
    width: 100% !important;
  }
  .meals-wrap .border {
    border: 0 none !important;
  }
  .footer-fine-print {
    text-align: center;
  }
  .recipe-actions .allergens {
    display: none;
  }
  .h-playlist {
    font-size: 22px;
    font-weight: 900;
  }
  main,
  .main {
    margin-top: 0;
    top: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #000;
  }
  .p-3 {
    padding: 0 !important;
  }
  .fixed-sidebar,
  .mt-md-3 {
    margin-top: 0;
  }
  .wizard-meals img {
    display: block !important;
  }
  .accordion-button {
    margin: 0 !important;
    padding-left: 0 !important;
  }
  .recipe-sidebar,
  .recipe-deets,
  .single-recipe-yield {
    border: 0 none !important;
    text-align: left !important;
  }
  .text-center {
    text-align: left !important;
  }
  .row,
  .col {
    padding: 5px !important;
  }
  .accordion-body {
    margin-bottom: 10px !important;
  }
  .mt-md-3,
  .my-auto {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .plan-img-print,
  .mb-sm-5,
  .accordion-body,
  .ms-2,
  .p-md-4,
  #preps .accordion-body {
    margin: 0 !important;
    padding: 0 !important;
  }
  .nav-heading {
    font-size: 18px !important;
    margin: 0 !important;
  }
  .print-padding {
    padding: 0 0 15px 0;
  }
  .mt-4 {
    margin-top: 10px;
  }
  h1 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  h5 {
    font-size: 16px !important;
    margin-bottom: 30px !important;
  }
  p,
  .markdown-notes p,
  .markdown-notes {
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }
  .lead,
  .print-yield,
  .preps .td-instructions,
  #preps .accordion-body td,
  .recipe-checkbox-table {
    font-size: 12px !important;
  }
  .text-orange {
    color: #000;
  }
  .print-padding-directions {
    padding: 18px 0 10px 0 !important;
  }
  .fixed-content {
    margin-top: 0;
  }
  .recipe-checkbox-table td {
    padding: 0 3px !important;
  }
  .table td {
    padding-left: 0;
    padding-top: 1px;
    padding-bottom: 2px;
  }
  .wizard-meals .nav-heading {
    margin-bottom: 20px;
  }
  .td-checkbox,
  .instructions-wrap {
    padding-right: 10px !important;
    padding-left: 10px;
  }
  .far .fa-square .srecipe-checkbox,
  .td-checkbox {
    display: none;
  }
  table.paging thead td,
  table.paging tfoot td {
    height: 0.25in;
  }
}

.markdown-notes p {
  font-family: "poppins", sans-serif;
}
