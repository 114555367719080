.profile-image {

  position: relative;

  img {
    border-radius: 0;
    width: 120px;
  }

  button {
    border-radius: 20px;
    font-size: .8rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0;
    position: absolute;
    right: 8px;
    bottom: -8px;
    border: 1px solid #fff;
  }
}

.accordion-button {

    font-family: 'poppins', sans-serif; font-weight: 400; font-size: 21px; margin: 0 1.2em 0 0; line-height: 1.5em;
}