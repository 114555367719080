#grocery-list {
   table {
      width: 100%;
   }
   .grocery-item {
      border-bottom: 1px solid rgba(211, 211, 211, 0.42);
   }

   .grocery-item td {

      padding: 19px 0 17px 0;
  }

   ul {
      list-style: none;
      padding-left: 0;
      margin: 5px 0 0 0;
   }

   ul li {color: #666;}

   .accordion-button {padding: 0;}
}
