$primary: #f47621;
$text-primary: #000;
$secondary: #17b3d0;
$light: #f6f6f6;
$body-bg: #ffffff;
$body-color: #000;
$border-radius: .45rem;
$border-width: 1px;
$border-color: #e8e8e8;

$white: #fff;
$black: #000;
$pink: #bd00cd;
$teal: #149db6;
$magic: #d23b95;
$orange: #f47621;
$green: #56954f;
$red: #c62127;
$yellow: #ec9b24;
$purple: #952163;
$linen: #f7f2ea;


$med-purple: #952163;
$light: #f6f6f6;
$darkgrey: #5e5e5e;
$grey: #ccc;
$cyan: #28c7e4;

$accordion-button-active-bg: #f6f6f6;
$accordion-button-active-bg: #fff;

$link-color: $primary;
$link-hover-color: #000;
$btn-link-color: #fff;

$table-cell-padding-y: 0.9rem;
$table-cell-padding-x: 0.9rem;
$table-cell-padding-y-sm: 0.45rem;
$table-cell-padding-x-sm: 0.45rem;

$dropdown-min-width: 15rem;
$dropdown-padding-x: 0.5rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.925rem;
$dropdown-font-size: 1.2rem;
$dropdown-bg: #fff;
$dropdown-border-radius: 0;
$dropdown-border-width: 1px;
$dropdown-divider-bg: #ccc;
$dropdown-link-color: #f47621;

$spacer: 1.5rem;

$border-width: 1px;

$font-size-base: 1.05rem;
$lead-font-size: $font-size-base * 0.85;
$lead-font-weight: 300;

$small-font-size: 0.845em;

$sub-sup-font-size: 0.88em;

$initialism-font-size: $small-font-size;

$blockquote-margin-y: 0.35rem;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y: $spacer;
$hr-color: inherit;
$hr-height: $border-width;
$hr-opacity: 0.25;

$legend-margin-bottom: 0.5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

$mark-padding: 0.2em;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$nav-link-color: #000;
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1.8rem;
$nav-link-font-size: 1.1rem;
$nav-link-font-weight: null;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

$nav-tabs-border-color: #000;
$nav-tabs-border-width: 0rem;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-active-bg: #8a5b5b;
$nav-tabs-link-active-border-color: $body-bg;

$navbar-light-color: $black;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $white;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;

$lead-font-size: $font-size-base * 1.05;
$lead-font-weight: 300;

$input-btn-focus-box-shadow: 0;
$input-focus-border-color: tint-color($secondary, 30%);

$input-border-color: $darkgrey;

$btn-close-opacity: 1;

$btn-box-shadow: 0;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 0.45;
$btn-active-box-shadow: 0;

$accordion-button-focus-border-color: $primary;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: #fff;

$component-active-color: $primary;
$component-active-bg: $light;

$form-select-border-width: $border-width;
$form-select-border-color: #a5a5a5;
$form-select-border-radius: $border-radius;
$form-select-box-shadow: 0;

$toast-max-width: 350px;
$toast-padding-x: 0.75rem;
$toast-padding-y: 0.5rem;
$toast-font-size: 0.875rem;
$toast-color: $white;
$toast-background-color: rgba($secondary, 1);
$toast-border-width: 0;
$toast-border-color: rgba($black, 0.1);
$toast-border-radius: $border-radius;

$toast-header-color: $white;
$toast-header-background-color: rgba($secondary, 1);
$toast-header-border-color: rgba($black, 0.05);

@import "node_modules/bootstrap/scss/bootstrap";

.form-check-input:focus {
  background-color: rgb(215, 212, 212);
  outline: 0;
  box-shadow: 0;
}

.form-check-input:checked {
  border-color: $primary;
  background-color: #fff;
  color: #fff !important;
}

.form-check {
  margin-bottom: 0.45em;
}

.grocery-item:last-child {
  border-bottom: 0 none !important;
}

body {
  background: #f6f6f6;
  font-family: "Poppins", sans-serif;
  font-size: $font-size-base;
  background-image: url("https://files.cleanfooddirtygirl.com/20240916094928/bg-paper.png");
   
}


.recipe-deets { background-image: url("https://files.cleanfooddirtygirl.com/20240916094928/bg-paper.png");}
p,
.recipe-checkbox-table,
.single-recipe-yield p {
  font-size: $font-size-base !important;
}

small,
.small {
  font-size: 0.945em !important;
}

a {
  text-decoration: none;
}

.recipe-checkbox-table div {
  padding-left: 1em;
}
.text-only-component {
  padding-left: 0 !important;
}
header {
  border-bottom: 1px solid #ccc;
}
.watermark {
  display: none;
}

h1,
h2,
h3,
h4 {
  font-family: "Playfair Display", serif;
}


h5 {
  font-size: 1.2em;
  font-weight: 600;
}
.markdown-notes p {
  font-family: "Playfair Display", serif;
  font-size: 1.1em;
  font-style: normal;
}
.markdown-notes ul {
  padding: 0;
}
.markdown-notes li {
  list-style-type: none;
}
.accordion-video .markdown-notes p,
#playlist p,
#playlist h2,
#playlist h3,
#playlist h4 {
  font-family: "poppins", sans-serif;
}
.h1,
h1 {
  font-size: 1.9375rem;
  line-height: 1.3em;
  font-weight: 300;
}

.h2,
h2 {
  font-size: 1.7375rem;
  line-height: 1.3em;
}

.h3,
h3 {
  font-size: 1.5375rem;
}
.caps {
  text-transform: capitalize;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

i {
  font-style: normal !important;
}
.bg-white {
  background: #fff;
}

.create-plan {
  background: url("https://assets.cleanfooddirtygirl.com/static/create-a-meal-plan.jpg") no-repeat;
  background-size: cover;
}

.form-control {
  border: 1px solid $border-color;
}

.nav-link {
  padding: 0.6em 0.9em !important;
}

@include media-breakpoint-down(sm) {
  .nav-link {
    font-size: 0.8em;
    padding: 3px 4px;
  }
}

/* Pointer cursor to use everywhere needed */
.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 768px) {
  .container {
    min-width: 915px;
  }
}

/*  Colors */
.pink {
  background-color: $pink;
}
.magic {
  background-color: $magic;
}
.teal {
  background: $teal;
}
.orange {
  background: $orange;
}
.red {
  background: $red;
}
.green {
  background: $green;
}
.yellow {
  background: $yellow;
}
.purple {
  background: $purple;
}
.med-purple {
  background: $med-purple;
}
.darkgrey {
  background-color: #d4d4d498;
}
.light {
  background: $light;
}
.cyan {
  background: $cyan;
}

.text-pink {
  color: $pink;
}
.text-magic {
  color: $magic;
}
.text-teal {
  color: $teal;
}
.text-orange {
  color: $orange;
}
.text-red {
  color: $red;
}
.text-green {
  color: $green;
}
.text-yellow {
  color: $yellow;
}
.text-purple {
  color: $purple;
}
.text-med-purple {
  color: $med-purple;
}
.text-darkgrey {
  color: #d4d4d498;
}
.text-light {
  color: $light;
}
.text-cyan,
.text-cyan .fa-action {
  color: $cyan;
}

.menu-pink .nav-link,
.menu-magic .nav-link,
.menu-teal .nav-link,
.menu-orange .nav-link,
.menu-red .nav-link,
.menu-green .nav-link,
.menu-yellow .nav-link,
.menu-purple .nav-link,
.menu-med-purple .nav-link,
.menu-darkgrey .nav-link,
.menu-light .nav-link,
.menu-cyan .nav-link {
  color: #000;   
}

.menu-pink,
.menu-magic,
.menu-teal,
.menu-orange,
.menu-red,
.menu-green,
.menu-yellow,
.menu-purple,
.menu-med-purple,
.menu-darkgrey,
.menu-light, 
.menu-cyan  {
  padding: 7px 0;
}

.menu-section {
  font-size: 1.3em;
  font-weight: 900;
}

.menu-section-icon {
  font-size: 0.9em;
}
.menu-item {
  font-size: 1.5rem;
}
.menu-pink .nav-link:hover {
  color: $pink;
}
.menu-magic .nav-link:hover {
  color: $magic;
}
.menu-teal .nav-link:hover {
  color: $teal;
}
.menu-orange .nav-link:hover {
  color: $orange;
}
.menu-red .nav-link:hover {
  color: $red !important;
}
.menu-green .nav-link:hover {
  color: $green;
}
.menu-yellow .nav-link:hover {
  color: $yellow;
}
.menu-purple .nav-link:hover {
  color: $purple;
}
.menu-med-purple .nav-link:hover {
  color: $med-purple;
}
.menu-darkgrey .nav-link:hover {
  color: #d4d4d498;
}
.menu-light .nav-link:hover {
  color: $light;
}
.menu-cyan .nav-link:hover {
  color: $cyan;
}

.menu-text {
  font-size: 1.1rem;
}

.nav-tabs .nav-link.active {
  color: $orange !important;
  background: #fff !important;
}
.nav-pink .nav-link {
  color: $pink !important;
}
.nav-pink .active {
  background-color: $pink !important;
  color: $white !important;
}

.nav-yellow .nav-link {
  color: $orange !important;
}
.nav-yellow .active {
  background-color: $orange !important;
  color: $white !important;
}

.nav-teal .nav-link {
  color: $orange !important;
}
.nav-teal .active {
  background-color: $orange !important;
  color: $white !important;
}

.nav-meals .nav-link:focus,
.nav-meals .nav-link:active {
  color: $orange !important;
}
.nav-recipes .nav-link:focus,
.nav-recipes .nav-link:active {
  color: $green !important;
}
.nav-more .nav-link:focus,
.nav-more .nav-link:active {
  color: $purple !important;
}
.nav-saved .nav-link:focus,
.nav-saved .nav-link:active {
  color: $teal !important;
}

.nav-meals,
.nav-recipes,
.nav-more,
.nav-saved,
.nav-signout {
  font-size: 1.5rem !important;
}

.modal-header {
  background-image: url("https://files.cleanfooddirtygirl.com/20240916094928/bg-paper.png");
}
.modal-content {
  border: 0 none;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.095) !important;
}
.alert-warning {
  background-color: #fff !important;
  border: 3px solid $yellow;
}

.homepage-container {
  margin-top: 120px;
}

@media only screen and (max-width: 749px) {
  .homepage-container {
    margin-top: 52px;
  }
}

/* Navigations */
.border-green {
  border-top: 5px solid $green !important;
}
.border-orange {
  border-top: 5px solid $orange !important;
}
.border-purple {
  border-top: 5px solid $purple !important;
}

.nav-meals .nav-link:active,
.nav-meals .nav-link:hover,
.nav-meals .nav-link:focus {
  background: $orange;
  color: #fff !important;
}
.nav-meals .nav-link:focus {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.nav-recipes .nav-link:active,
.nav-recipes .nav-link:hover,
.nav-recipes .nav-link:focus {
  background: $green;
  color: #fff !important;
}
.nav-recipes .nav-link:focus {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.nav-saved .nav-link:active,
.nav-saved .nav-link:hover,
.nav-saved .nav-link:focus {
  background: $cyan;
  color: #fff !important;
}

.nav-more .nav-link:active,
.nav-more .nav-link:hover,
.nav-more .nav-link:focus {
  background: $purple;
  color: #fff !important;
}
.nav-more .nav-link:focus {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.nav-link-magic .nav-link:active,
.nav-link-magic .nav-link:hover {
  background: $magic;
  color: #fff !important;
}

#offcanvasMenu {
  width: 400px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  #header-icons-group {
    margin-right: 7px;
  }
}

#header-search-btn:hover {
  color: $link-color;
}
.main-menu .dropdown-menu {
  margin-top: -2px !important;
}

.accordion-body {
  padding: 2rem 2rem 3rem 2rem;
}
.accordion-button:not(.collapsed) {
  color: $primary;
}
.dark-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("https://assets.cleanfooddirtygirl.com/static/arrow-white.png");
}
.prep-tab .accordion-button:not(.collapsed) {
  color: $body-color;
  background-color: $light;
}
.accordion-item {
  border: none;
}
.accordion-header {
  border-bottom: 1px solid #dee2e6;
}
.show .accordion-body {
  border-top: 0 none;
}
.accordion-button .fa-circle,
.accordion-button .fa-check-circle {
  font-size: 1.65rem;
}
.btn-primary,
.btn-secondary,
.btn-secondary:disabled,
.btn-secondary:visited,
.btn-primary:hover,
.btn-primary:focus,
.btn-secondary:hover {
  color: #fff;
}
.btn-white {
  background-color: #fff;
  color: #666;
}

.btn-white:disabled {
  pointer-events: none;
  opacity: 0.55;
}

hr {
  opacity: 0.1;
}

/* component meal planner */
.btn-mid {
  border-color: #e8e8e8;
  color: #6c757d;
}
.dropdown-item {
  font-family: "Poppins", sans-serif;
}
.main-menu-dd .dropdown-item:hover {
  background: none;
}
.full-width {
  width: 100%;
}
.component-warning p {
  margin-bottom: 0.6em;
}

/* Grocery List */
.grocery-item,
.table-borderless {
  font-size: $font-size-base;
}
.grocery-item .accordion-button:not(.collapsed) {
  background: none;
}
.grocery-item {
  border-bottom: 1px solid #e8e8e8;
  margin-top: 8px;
}
.grocery-item td {
  padding: 27px 20px 15px 0;
}
.grocery-item .accordion-button {
  padding-top: 0;
}
.items-have .accordion-button {
  background-color: #f6f6f6;
}

.instructions-wrap {
  font-size: 14px;
  margin-left: 8px;
  padding-left: 10px;
}
.prep-tab .recipe-checkbox {
  margin-right: 10px;
}
.instructions-wrap {
  padding: 0 none !important;
}
.preps .table-secondary,
.preps .table,
.preps .td-instructions,
.prep .td-checkbox {
  font-size: 15px;
  line-height: 22px;
  vertical-align: top;
}
#preps tr {
  border-bottom: 1px solid #e8e8e8;
}
#preps .accordion-body {
  padding: 10px 10px;
}
#preps .accordion-body td {
  font-size: 14px !important;
}
#preps .btn {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 4px;
  font-size: 13px;
}

.custom-items-table {
  width: 100%;
}

.table-secondary {
  --bs-table-bg: #fff;
  --bs-table-striped-bg: #c7e4ea;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd8dd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1dee4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd8dd;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background: #f2f2f2 !important;
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}
.badge {
  margin-left: 0.35rem;
}
.blockquote-footer {
  margin-top: 0.17em;
}

.h-shadow {
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
}

/* Billing */
.plan-amount {
  font-size: 30px;
  font-weight: 400 !important;
}
.plan-price {
  font-size: 12px;
  font-weight: 400;
}
.plan-payment-schedule {
  font-size: 13px;
}
.subscription-box .col-md-4 {
  width: 100% !important;
}
.plan-name {
  font-size: 14px;
}
.btn-secondary-light {
  background-color: #96dce9;
  border-color: #96dce9;
}
.membership-selected .btn-secondary {
  background-color: $primary;
  border-color: $primary;
}
.membership-card {
  background-color: #f2f2f245;
}
.membership-selected .card {
  background-color: $white;
  border: 3px solid $primary;
}

/* Cards */
.card h2 {
  font-size: 1.1em;
}
.card-ratings {
  font-size: 1.2em;
  padding: 0;
  color: $primary;
}

/* Buttons */
/* Default State, used on cards for stash and favorite */
.btn-light {
  color: #666;
}
/* Default State, used on stash/ */
.btn-light {
  color: #666;
}
.btn-link {
  color: $primary;
  text-decoration: none;
  background: none;
}
.btn-link-dark {
  color: $text-primary;
  text-decoration: none;
  background: none;
}
.btn-link-dark:hover {
  color: $primary;
}
.btn-outline-primary:hover {
  color: $white;
}
.btn-primary:visited,
.btn-primary:hover {
  color: #fff;
}
textarea.form-control {
  font-size: 18px;
  padding: 1em;
}
.form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.input-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.btn.btn-primary:hover {
  opacity: 0.4;
}
.btn.btn-primary:active {
  background-color: red;
}
.btn-primary:disabled {
  color: #fff;
}
.header .btn {
  text-decoration: none;
  font: 0.5em "Poppins", sans-serif;
  padding: 0.3em 0.6em;
}
#sign-up,
#sign-in,
#forgot-password,
#thank-you {
  max-width: 750px;
  background-color: #fff;
  padding: 2em;
}

.h-alt {
  font-family: "poppins", sans-serif;
  font-weight: 900;
}

.h-handwriting {  font-family: "Reenie Beanie", cursive;
  font-weight: 400;
  font-style: normal;
font-size: 2.5em;}
.h-light {
  font-weight: 300;
}
.h-playlist {
  font-family: "poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  margin: 0 1.2em 0.3em 0;
  line-height: 1.5em;
}
.accordion-video {
  padding: 1.3em;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.modal .btn-close {
  color: #fff !important;
}

/* Review Modal */
.btn-submit-review {
  clear: both;
}
.modal-dialog .review-stars,
.leave-rating-stars,
.modal-dialog .time-rating-review {
  color: $primary;
  font-size: 1.5rem;
}
.modal-dialog .review-stars p {
  color: #000;
  margin-top: 0.8em;
}
#reviews-modal .ratings {
  font-size: 1.2em;
}
#reviews-modal .blockquote .your-rating {
  font-size: 0.9em;
}

.btn-primary:hover {
  color: #fff;
}

.page-item.active .page-link {
  border-color: #dee2e6;
}
.alert-secondary {background-color: #ececec; border: 1px solid $primary; color: #000;}
.pagination-lg .page-link {
  padding: 0.45rem 1rem;
  font-size: 1rem;
}

.leave-rating-stars,
.time-rating-review {
  color: $primary;
}
.timing {
  color: $primary;
}
#search-modal h2 {
  font-size: 1.2em;
}
#search-modal .card-body .ratings {
  font-size: 1em;
  padding: 0 !important;
  margin-bottom: 5px;
}
#search-modal .p-4 {
  padding: 1.25rem 1.25rem 0.2rem 1.25rem !important;
}
#search-modal .recipe-description {
  display: none;
}
#search-modal .recipe-timing {
  font-size: 0.8rem !important;
  margin-bottom: 0;
}
.modal-open .applied-filters .bg-white {
  background-color: #f6f6f6 !important;
}
.facet-count {
  color: gray;
}

#add-to-meal-plan-modal .form-select {
  background-color: #efefef80;
}
#add-to-meal-plan-modal .select-plan {
  width: 100%;
}
#add-to-meal-plan-modal select {
  margin: 8px 0;
  font-size: 17px;
}

.meal-preview .card-title {
  margin-bottom: 5px;
}
.meal-preview h2 {
  font-size: 1.3em;
}
.mp-saved .card-title {
  text-transform: capitalize;
}
.mp-saved h2 .card-link {
  font-size: 1.7rem;
  margin-bottom: 0.25rem;
}
.mp-saved h2 {
  margin-bottom: 0.1em;
}
.description {
  font-size: 0.88em;
}

.table-scroll,
.table-scroll table {
  overflow-x: scroll;
}

.alert-warning {
  color: #605f5f;
  background-color: #fcffb2;
  border-color: none;
}

.ril-caption-content {
  background-color: #fff !important;
}
.ril__captionContent {
  color: #000 !important;
}

@media (max-width: 991px) {
  #search-modal .col-md-4 {
    width: 100% !important;
  }
}
.card-title-bright {
  font-size: 1.4em !important;
  font-weight: 900;
  color: #fff;
  font-family: Poppins, sans-serif;
}


p {
  font-size: 14px;
}
#featured-documents {
  background: $secondary;
  padding: 8px 25px 0 20px;
  margin: 0 0 25px 0;
}
#featured-documents .card {
  border: 0 none;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
}
.featured .accordion-button {
  background: $secondary;
}
.featured .collapsed {
  background: #fff;
  margin-bottom: 1.2em;
}
.featured .accordion-button:not(.collapsed) {
  color: #fff !important;
}

.recipe-checkcircle {
  font-weight: 300;
  font-size: 1.65rem;
  padding-right: 3px;
}
.unchecked {
  color: #b2b2b2 !important;
}
.fa-circle-notch {
  font-weight: 300;
}
.h2-recipe {
  font-family: "playfair display", serif;
  font-size: 1.6rem;
  line-height: 1.7rem;
}
.h2-inactive-recipe {
  font-family: "playfair display", serif;
  font-size: 1.6rem;
  line-height: 1.7rem;
}
.h2-recipe-hover:hover {
  color: $primary;
}

/* Hide download */
.download-action,
.hide {
  display: none;
}

.tagline {
  color: #999;
  margin-bottom: 2rem;
  font-size: 22px;
  padding: 0 2em;
}
#tile-collection h1,
.page-title {
  line-height: 1.3em;
  font-size: 3.25em;
  color: #000;
  text-shadow: 1px 1px 1px rgba(204, 204, 204, 0.8);
}

.fixed-sidebar {
  margin-top: 54px;
}

/* Bright lead magnet cards */
.sales-card h2 {font-size: 2em;}
.max-card-width {max-width: 900px;}
.sales-card img { max-width: 200px;
  width:100%;}
/*Meal plan styling with fixed sidebar */

.add-item-input {
  border: 1px solid #ccc !important;
  padding: 10px;
}

.main-meal-plan-wrap .container-fluid {
  padding: 0;
}
.main-meal-plan-wrap .accordion-item {
  margin-bottom: 1em;
}

.main-meal-plan-wrap .accordion-button:not(.collapsed) {
  color: $black !important;
  border-bottom: 5px solid $orange;
}
.main-meal-plan-wrap .accordion-body {
  padding: 2rem 1.5rem 3rem 1.5rem;
}

.main-meal-plan-wrap .accordion-header.show {
  border-bottom: 1px solid $orange !important;
}

.main-meal-plan-wrap .show .accordion-body {
  border: 0 none;
}

.nav-sidebar .nav-pills .nav-link {
  border-radius: 0;
}

.used-in {
  line-height: 1.3em;
  font-size: 14px !important;
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 400;
}
.nav-item-child .d-md-inline,
.nav-item-child .d-lg-inline {
  padding-left: 29px !important;
}
/* custom colors for accordion on fixed sidebar pages */
.fa-check-circle {
  color: #989898;
}
.meals-list .recipe-actions .btn {
  padding: 9px;
}
.meals-list .fa-check-circle {
  font-size: 25px;
}
.meals-list .child-recipes {
  font-size: 1.1rem;
}

.magic-accordion .accordion-button:not(.collapsed) {
  color: $magic;
}
.magic-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("https://assets.cleanfooddirtygirl.com/static/arrow-pink.png");
}
.accordion .accordion-checked {
  color: #989898;
}

.orange-accordion .accordion-button:not(.collapsed) {
  color: $orange;
}
.orange-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("https://assets.cleanfooddirtygirl.com/static/arrow-light-orange.png");
}

.plan-tabs .nav-pills .nav-link.active {
  color: $orange !important;
}

/* required for sidebar to behave on larger screens */
@media (min-width: 576px) {
  .h-sm-100 {
    height: 100%;
  }
}
/* hide sidebar scrollbar */
#sidebar {
  scrollbar-width: none; /* Firefox */
}
#sidebar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
/* hide stuff when there is less vertical space */
@media (max-height: 900px) {
  .description {
    display: none !important;
  }
}
@media (max-height: 700px) {
  .poster {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .main-meal-plan-wrap {
    background-color: #fff;
  }
  .main-meal-plan-wrap .accordion-item {
    margin-bottom: 0;
  }
  .meal-plan-sidebar .nav {
    background-color: $orange;
  }

  .accordion-item {
    border: 1px solid $border-color;
  }
  .profile-image button {
    left: 230px;
    position: relative;
  }
}

.cancel-form .input-group-text {
  padding-left: 0 !important;
}
.cancel-form .form-check-input {
  width: 1em;
  height: 1em;
}
.cancel-form .input-group-text {
  font-size: 1.1em;
}

/* Sidebar for Meal Plans, Meal Planner */

.search-result .card-title h2 {
  margin: 27px 0 0.3em 0;
  clear: both;
  font-size: 1em;
}
.search-result .card-title a h2 {
  color: #000;
}

.make-ahead .form-check {
  margin-bottom: 0.15em;
}
.input-group-text {
  padding-left: 0;
}
.nobullet {
  list-style-type: none;
}

@include media-breakpoint-down(md) {
  .nav-sidebar {
    background: $orange;
  }
  .nav-magic {
    width: 100%;
    background: $magic;
  }
  .nav-sidebar .nav-link {
    text-align: left;
    width: 100%;
    padding: 8px 10px;
  }
  .nav-sidebar .nav-link {
    color: $white;
  }
  .nav-sidebar .nav-link {
    border-radius: 0;
    border: 0 none;
    background: none;
  }
  .nav-sidebar .nav-link.active {
    color: $orange !important;
    border-radius: 0;
    background-color: #fff !important;
  }
  .nav-magic .nav-link.active {
    color: $magic !important;
  }
  .nav-sidebar .nav-pills .nav-link {
    font-size: 1rem;
  }
  .fixed-sidebar {
    margin-top: 49px;
  }
  .fixed-content {
    margin-top: 0;
    padding: 0;
  }
  .xs-intro {
    font-size: 1.5em !important;
  }
  .nav-heading {
    font-size: 1.8em !important;
  }
  .accordion-item {
    border-radius: 0 !important;
  }
  .search-result .card-title h2 {
    margin-top: 30px !important;
    font-weight: 500;
  }
  .nav-sidebar {
    width: 100%; /* Full width */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 4; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 65px;
  }
  .main-content-wp,
  .main-content-mmp {
    width: 100%; /* Full width */
    margin-left: 0; /* Same as the width of the sidebar */
    margin-right: 0;
    padding: 0 10px;
    overflow-x: hidden; /* Disable horizontal scroll */
  }
  .mobile-header-mmp {
    margin-top: 78px;
  }
  .mobile-header-wp {
    margin-top: 78px;
  }
}

@include media-breakpoint-up(md) {
  .nav-sidebar {
    width: 100%;
  }
  .nav-sidebar .nav-link {
    text-align: left;
    width: 100%;
  }
  .fixed-sidebar {
    margin-top: 78px;
  }
  .nav-sidebar .nav-link.active {
    color: $orange !important;
    border-right: 4px solid $orange;
    border-radius: 0;
  }
  .nav-magic .nav-link.active {
    color: $magic !important;
    border-right: 4px solid $magic;
  }

  .nav-sidebar .nav-link {
    border-bottom: 1px solid #e8e8e8 !important;
  }
  .fixed-content {
    margin-top: 79px;
  }
  .nav-heading {
    font-size: 2.2em !important;
  }
  .sidenav-wp,
  .sidenav-mmp {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 25.5%; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  .main-content-wp,
  .main-content-mmp {
    margin-left: 25.5%; /* Same as the width of the sidebar */
    padding: 0 10px;
    overflow-x: hidden; /* Disable horizontal scroll */
  }
}

@include media-breakpoint-up(md) {
  .main-meal-plan-wrap {
    padding: 0 4em;
    max-width: 60rem;
    margin: 0 auto;
  }
  .sidenav-wp {
    width: 24.5%; /* Set the width of the sidebar */
  }
  .main-content-wp {
    margin-left: 24.5%; /* Same as the width of the sidebar */
  }
  .sidenav-mmp {
    width: 16%; /* Set the width of the sidebar */
  }
  .main-content-mmp {
    margin-left: 16%; /* Same as the width of the sidebar */
  }
}

@include media-breakpoint-up(lg) {
  .plan-tabs .nav-pills .nav-link {
    text-align: left;
    padding-left: 10px;
  }
  .sidenav-wp {
    width: 24.5%; /* Set the width of the sidebar */
  }
  .main-content-wp {
    margin-left: 24.5%; /* Same as the width of the sidebar */
  }
}

@include media-breakpoint-up(xl) {
  .sidenav-wp {
    width: 16.5%; /* Set the width of the sidebar */
  }
  .main-content-wp {
    margin-left: 16.5%; /* Same as the width of the sidebar */
  }
}

/*Meal Plan colors */
.mp-name {
  padding: 8px;
  display: block;
  width: auto;
  color: #fff;
}
.add-day .btn-light {
  background-color: $secondary;
  color: #fff;
  margin-top: 30px;
}
.nav-colored .nav-link {
  font-size: 1.45em !important;
}
.nav-plans .nav-link {
  color: #fff;
}
.accordion-button:is(.collapsed) .badge {
  color: #666 !important;
}
.main-menu .nav-link {
  padding: 0 0.75em !important;
}

@media only screen and (max-width: 600px) {
  #tile-collection h1,
  .page-title {
    line-height: 1.1em;
    font-size: 2.5em;
    padding: 0.3em 0 0 0;
  }
  .tagline {
    margin-bottom: 1.4rem;
  }
  .recipe-preview .left-checkbox {
    margin: 5px 3px 0 0;
  }
  .nav-colored .nav-link {
    font-size: 0.95em !important;
    padding-left: 9px;
    padding-right: 9px;
  }
  .portions-recipe .input-group {
    width: 55%;
    margin: 0 auto;
  }
  .tile-badge-wrap img {
    width: 58px;
  }
}

.h-playlist span,
.h-playlist {
  font-size: 1em;
  font-weight: 700;
}

/* Recipe New Layout */

.recipe-featured-image {
  max-width: 100%;
  max-height: 500px;
  overflow: hidden;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.meal-preview-image {
  max-height: 400px;
  border-radius: $border-radius !important;
}

@include media-breakpoint-down(md) {
  .recipe-featured-image {
    max-height: 300px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: $border-radius !important;
  }

  .meals-list .recipe-featured-image {
    border-bottom-right-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
  }
}

.portions-recipe .portions-wrap {
  float: none;
}
.recipe-actions-wrap {
  margin-left: -0.75em;
}

@include media-breakpoint-only(xl) {
  .recipe-actions .btn {
    padding-right: 0.14em;
  }
}

/* set servings on recipe only */
.portions-recipe .portions-wrap {
  float: none;
}
.meal-planner-portions,
.portions-wrap {
  max-width: 218px;
}

.rounded {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.make-ahead .col {
  padding-left: 0;
}
@media only screen and (max-width: 1390px) {
  .recipe-preview .left-checkbox {
    margin: 7px 3px 0 0;
  }
}

@media only screen and (max-width: 769px) {
  .rounded {
    width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .col-lg-3 {
    width: 50%;
  }
}

.fa-action {
  font-size: 1em !important;
}
.input-search {
  border-color: $grey !important;
}
.btn-ex-small,
.ex-small {
  font-size: 14px !important;
}
/* Print recipe rules */
@media print {
  .fixed-sidebar {
    display: none;
  }
  .checkbox {
    display: none;
  }
  #beacon-container-body,
  #beacon-container {
    display: none !important;
  }
  .full-print {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .col-md-4,
  .col,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .mb-sm-5 {
    margin-bottom: 0;
  }
  .print-no-margin {
    margin: 10px none;
  }
  .recipe-wrap .ratings {
    margin-top: 30px;
  }
  p,
  p em,
  div .markdown-notes,
  .recipe-ingredients-directions,
  .recipe-ingredients-directions p,
  .recipe-ingredients-directions li,
  .recipe-checkbox-table,
  .recipe-checkbox-table td,
  .markdown-notes li,
  .markdown-notes p,
  .recipe-checkbox-table p {
    font-size: 14.5px !important;
  }
  .mb-3 {
    margin-bottom: 0rem !important;
  }
  .recipe-featured-image {
    max-width: 250px;
    height: auto;
    margin-bottom: 20px;
  }
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }
  #left-column {
    display: none;
  }
  .overflow-hidden,
  .overflow-auto {
    overflow: visible !important;
  }
  .shadow-sm,
  .shadow,
  .magic-meal-plan-card {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
  .btn-stacked,
  .main-menu,
  .nav-align-right,
  .form-control,
  .dropdown,
  .fa-image,
  .fa-info-circle,
  .single-recipe-actions,
  .add-to-plan,
  .btn,
  .footer-icons,
  #menu {
    display: none;
  }
  main {
    margin-top: 0 !important;
  }
  .allergens li {
    text-align: left !important;
  }
  .accordion-body,
  .accordion-item {
    border: 0 none !important;
  }
  .accordion-body {
    padding-top: 0;
  }
  .print-full {
    width: 100% !important;
  }
  .recipe-wrap .recipe-info {
    width: 100% !important;
    float: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .mb-sm-5 {
    margin-bottom: 0 !important;
  }
  .meal-preview {
    padding: 0;
    margin: 0;
  }
  .watermark-recipe {
    float: left !important;
  }
  .col-md-4,
  .col,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    width: 100% !important;
    margin-bottom: 1px;
  }
  .mb-sm-5,
  .mb-3,
  .mb-2,
  .my-1,
  .mb-4,
  .ms-2 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .p-2,
  .pt-2 .p-3,
  .p-sm-3,
  .strip-padding,
  .p-3,
  .px-3 .px-md-4 .py-2,
  .ms-3 {
    padding: 0 !important;
  }
  .strip-margin-print,
  .ms-md-3 {
    margin: 0 !important;
  }
  .strip-padding-print {
    margin: 0 !important;
  }
  .magic-meal-plan-card .make-ahead,
  .print-left {
    float: left !important;
  }
  .recipe-day {
    display: none;
  }
  #grocery-list .grocery-item td {
    padding: 10px 0 5px 0 !important;
  }
  .magic-meal-plan-card,
  .border,
  .add-meal {
    border: 0 none !important;
    margin-bottom: 2px;
    padding-bottom: 2px;
  }
  .magic-accordion .card-body .p-3 {
    display: none;
  }
  .make-ahead,
  .make-ahead .col {
    padding: 0 !important;
  }
  .mb-2 {
    margin-bottom: -15px !important;
  }
  .fa-check {
    display: none;
  }
}
.meal-plan-sidebar p {
  font-size: 0.8em !important;
}
.meal-delete {
  font-size: 1.4em !important;
  margin: 5px 0 0 0;
}
.meal-card-actions {
  margin-left: 0.09rem;
  padding-top: 0.3rem;
  padding-bottom: 0.1rem;
}
.bottom-rounded {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.list-group-item h2 {
  font-size: 1.35rem;
  margin-bottom: 8px;
  margin-top: 8px;
}
.list-group-item .list-actions .btn {
  margin-bottom: 0;
}
.list-group-item .list-actions p {
  margin-bottom: 0;
  padding-bottom: 0 !important;
}
.list-group-item .list-actions .btn {
  margin-bottom: 0;
  padding-bottom: 0 !important;
}
.meal-list-actions {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-bottom: 0.3rem;
}

.document-type .ratings {
  font-size: 0.8rem;
  padding: 0 0.3em 0 0;
  margin-top: -1px;
}


/* public reviews styling */
.public-reviews .list-group-item {border: 0 none; padding: 0;}