
main, .main {
    margin-top: 90px;
}



/*  Header */

.header-logo {max-width: 160px;}


.header .btn {
    text-decoration: none;
    font: 0.7em 'Poppins', sans-serif;
    padding: 0.2em 0.8em;
}

.header-icons, .footer-icons, .menu-icons {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f1ea";
    font-size: 2em;
}

.nav-align-right a {
    color: #000;
}

.header-icons a:hover {
    text-decoration: none;
}

.main-menu .nav-align-right ul a {margin-left: 0;}

.main-menu .logo img {
    max-width: 210px; 
}

@media only screen and (max-width: 768px) {

    .header-logo {max-width: 130px;}


    .header .btn {
        text-decoration: none;
        font: 0.5em 'Poppins', sans-serif;
        padding: 0.3em 0.6em;
    }
    

}
/*  Footer */

footer {
    padding: 1.4rem 1rem !important;
    margin-top: 14px;
}

.fa-brands {
    font-family: "Font Awesome 5 Free";
}

.footer-fine-print a, .footer-fine-print p, .footer-fine-print {
    color: #858585;
    font-size: 14px;
    margin-bottom: 0;
    text-decoration: none;
}

.footer-icons .p-2 {
    padding: .5rem !important;
    margin: 0 3px !important;
}

.footer-logo {
    text-align: left;
    margin: 15px 0;
}

.footer-icons {
    text-align: right;
}

.menu-icons {
    text-align: center;
}

.footer-anonymous .footer-icons {
    text-align: center;
}
.footer-icons a, .menu-icons a {
    color: #858585;
}

.footer-icons a:hover, .menu-icons a:hover {
    color: #f47621;
}

@media only screen and (min-width: 601px) {
    .footer-icons .strip-right {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
}

@media only screen and (max-width: 749px) {

    .footer-fine-print p {
        text-align: center
    }

    .footer-fine-print {
        margin: 10px;
    }

    main, .main  {margin-top: 67px;}
}


