@media only screen and (max-width: 575px) {
    .carousel-item {
        background-color: #FFFFFF;
    }
}

@media only screen and (min-width: 576px) {
    .carousel-item {
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
}

.carousel-control-prev, .carousel-control-next {
    width: 10%;
}

.testimonial-text {
    font-size: smaller; /* = 14px */
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    width: 20px;
    height: 20px;
}