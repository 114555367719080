a.card-link, a.card-link:hover, a.card-link:visited {
    text-decoration: none;
    color: #212529;
}


.card-title, .card-title .card-link {
    color: #4D4D4D;
    font-size: 1.2em;

}

.card-title {
    margin-bottom: 0.11rem;
}

.card-title h2 {font-weight: 400 !important;}
/*  Tiles Hover State */
.brighten {
    -webkit-filter: brightness(100%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.brighten:hover {
    -webkit-filter: brightness(120%);
}

/* .brighten:hover .card-title {
    color: #FF4000;
} */


#tile-collection .card-title, .anon-footer .card-title {
    font-size: 1.7rem;
    font-family: 'playfair display', serif;
  }

  .anon-footer .card-title {
    font-size: 1.4rem;
  }


  #tile-collection .card-title
  {
    margin-bottom: 5px !important;
  }

 
.tile-purple .card-title:hover, .tile-purple .brighten:hover .card-title {color: #5A2D85;}
.tile-orange .card-title:hover, .tile-orange .brighten:hover .card-title {color: #F68712;}
.tile-teal .card-title:hover, .tile-teal .brighten:hover .card-title {color: #149DB6;}
.tile-pink .card-title:hover, .tile-pink .brighten:hover .card-title {color: #BD00CD;}
.tile-magic .card-title:hover, .tile-magic .brighten:hover .card-title {color: #F80EB7;}
.tile-yellow .card-title:hover, .tile-yellow .brighten:hover .card-title {color: #F4C22E;}
.tile-green .card-title:hover, .tile-green .brighten:hover .card-title {color: #87CD4F;}

.card-body p {margin-bottom: 0;}

#anonymous-home .card-title {
    font-size: 1.4em; margin-bottom: 0 !important;
  }

.scaled-tile-image {
  object-fit: cover;
  aspect-ratio: 15 / 9;
}