#password::-ms-reveal,
#passwordConfirmation::-ms-reveal {
    display: none;
}

#btn-show-password,
#btn-show-password-confirmation {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: rgb(232, 232, 232);
    background-color: #FFFFFF;
    color: rgb(232, 232, 232);
}

#password:focus + #btn-show-password,
#passwordConfirmation:focus + #btn-show-password-confirmation {
    color:#17B3D0;
    border-color: #ccc;
}

#btn-show-password:focus,
#btn-show-password-confirmation:focus {
    color:#17B3D0;
}