.recipe-day  {width: 50px; height: 50px; position: absolute; margin-top: 10px; margin-left: 10px;} 
.recipe-day span {background: #fff;
    border-radius: 80px;
    font-size: 20px;
    display: block;
    text-align: center;
    line-height: 40px;
    font-weight: 300;
    width: 40px;
    height: 40px;
 } 
.recipe-day  img {width: 100%}

/* Recipe from Meal Plan Menu Action */

/* .recipe-actions a {color: #f47621;  padding: 9px 14px; height: 50px; width: 50px;
    display: inline-block; width: auto; float: left; margin: 0 .2rem 0 0;} */
.recipe-actions a:hover {color: #000;}
