.markdown-notes p {
  font-family: "poppins", sans-serif;
}

.seen-notification a {
  color: darkgray;
}
.seen-notification a:hover {
  color: black;
  text-decoration: underline;
}

.new-notification a{
  color: black;
}

.new-notification a:hover {
  text-decoration: underline;
}

.custom-link {
  cursor: pointer;
}

.custom-link:hover {
  color:black;
  text-decoration: underline;
}

.btn-link {
  color: black;
}

.btn-link:hover {
  color: rgb(255, 92, 38);
}

.notification-list {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.collapse-btn {
  background-color: #f6f6f6;
  border: none;
  padding: 0.375rem 0.375rem;;
}
