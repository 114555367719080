.micros .span {text-align: right; padding: 8px;}
.micros {list-style-type: none;}
.macros {text-align: center;}
.macros {color: #666;}
.macros h4 {font-family: 'poppins', sans-serif !important; color: #000; font-size: 1.8em; line-height: .6em;}
.nutrient-fact {text-align: right; width: auto;}
.micros {border: 5px solid #000;}
.micros th {border-bottom: 5px solid #000;}
.micros th td {border-bottom: 0 !important;}
 .micros-header  {border-bottom: 5px solid #000 !important;}
.micros .row:last-child {border-bottom: 0 none; padding-bottom: 0;}
.macros .col {margin: 30px 0;}
.micros tr {font-size: 15px; border-bottom: 1px solid rgb(148, 148, 148) !important;}
.micros td {padding: 5px 10px;}