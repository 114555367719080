.document a {
  color: #000;
  :hover {
    color: hsl(15, 100%, 50%);
  }
}

.document .document-title {
  font-size: 1.05rem !important;
  font-weight: 500;
  line-height: 1.2;
  margin: 0.2em 0;
}

.document .document-buttons {
  margin-top: 0.5rem;
}

.document .document-type {
  font-size: 0.8rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.document img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
}

.document .list-view img {
  width: 90px;
}