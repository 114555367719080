@media only screen and (max-width: 575px) {
    #thank-you {
        background-color: #F6F6F6;
    }

    #thank-you-animation {
        width: 100%;
    }
}

.square-img {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
}

.thumbnail-text {
    font-size: 0.75em;
    margin-top: 0.375rem;
    margin-bottom: 00.375rem;
    text-align: center;
}