.recipe-tags .badge {font-size: 1.2em; font-weight: 300; padding: 10px 21px; border-radius: 15px !important;}

.notes-icon {position: relative; top: 0.1em;}
.notes-icon-accordion {position: relative; top: 0.1rem;}
.notes-badge {padding: 0.3em 0.6em 0.2em 0.6em}
.notes-badge-accordion {padding: 0.28em 0.5em 0.25em 0.5em; margin-left: 0.2em;}
.notes-badge-wp {padding: 0.2em 0.5em 0.17em 0.5em; margin-left: 0.5em; color: #f68712;}
@media (min-width: 576px) {
  .notes-badge-wp {position: relative; top: -1px;}
}
@media (max-width: 575.98px) { 
  .notes-icon-wp {position: relative; top: 0.1em;}
  .notes-badge-wp {margin-left: 0.2em;}
}

