.footer-text {
    font-size: small !important;
}

@media only screen and (max-width: 575px) {
    #sign-up {
        background-color: #F6F6F6;
    }
}

.black-link {
    color: black;
    text-decoration: underline;
}