#password::-ms-reveal {
    display: none;
}

#btn-show-password {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: rgb(232, 232, 232);
    background-color: #FFFFFF;
    color: rgb(232, 232, 232);
}

#password:focus + #btn-show-password {
    color:#17B3D0;
    border-color: #ccc;
}

#btn-show-password:focus {
    color:#17B3D0;
}