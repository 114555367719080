.checkcircle:active {
  transform: translateY(2px);
}

@keyframes fancy-spin {
  0% {
    transform: rotate(0turn) scale(1);
  }
  33% {
    transform: rotate(1turn) scale(1);
  }
  66% {
    transform: rotate(1turn) scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.complete {
  animation: fancy-spin 1500ms;
  animation-iteration-count: 1;
}