.checkbox {
  border: 1px solid #6c757d;
  border-radius: 5px;
  padding: 2px 3px;
  font-size: 16px;
}

.checkbox-filled {
  background-color: #6c757d;
}

.checkbox:active {
  transform: scale(1.1);
}